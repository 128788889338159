import {Component, HostListener, inject, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService, RoleResponse} from './shared/services/auth.service';
import {combineLatest, EMPTY, Subscription, timer} from 'rxjs';
import {User} from './shared/model/User';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterModule} from '@angular/router';
import {DEFAULT_EXPERIMENTAL_SETTINGS, SettingsService} from './shared/services/settings.service';
import {faCodeBranch, faDesktop, faMap, faTv} from '@fortawesome/free-solid-svg-icons';
import {UserMessageService} from './shared/services/user-message.service';
import {UserMessageEntry} from './shared/model/UserMessage/UserMessageEntry';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {PREDEFINED_NAVIGATIONS} from './shared/model/NavigationCommand';
import {BrowserModule, Title} from '@angular/platform-browser';
import {UserSettingsService} from './shared/services/user-settings.service';
import {CommonModule, DOCUMENT} from '@angular/common';
import humanizeDuration from "humanize-duration";

import '@cds/core/icon/register.js';
import '@cds/core/accordion/register.js';
import '@cds/core/alert/register.js';
import '@cds/core/button/register.js';
import '@cds/core/checkbox/register.js';
import '@cds/core/datalist/register.js';
import '@cds/core/file/register.js';
import '@cds/core/forms/register.js';
import '@cds/core/input/register.js';
import '@cds/core/password/register.js';
import '@cds/core/radio/register.js';
import '@cds/core/range/register.js';
import '@cds/core/search/register.js';
import '@cds/core/select/register.js';
import '@cds/core/textarea/register.js';
import '@cds/core/time/register.js';
import '@cds/core/toggle/register.js';
import * as _ from 'lodash';
import {isNil} from 'lodash';
import {DirectMessageTargetIdService, TargetEnum} from './shared/services/direct-message-target-id.service';
import {ApprovalService} from './shared/services/approval.service';
import {ApprovalStatus} from './shared/model/gen/utm';
import {PermissionParser} from "./shared/permissions/PermissionParser";
import {toSignal} from "@angular/core/rxjs-interop";
import {PermissionService} from "./shared/permissions/service/permission.service";
import {of} from "rxjs/internal/observable/of";
import {AudioAlertsService, AudioAlertType} from './shared/services/audio-alerts.service';
import {ResponsiveScreenService} from "./shared/services/responsive-screen.service";
import {ClarityModule, ClrNavigationModule} from "@clr/angular";
import {LeafletComponentsModule} from "@ax/ax-angular-map-leaflet";
import {CdsModule} from "@cds/angular";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "./shared/shared.module";
import {ServicesModule} from "./shared/services/services.module";
import {PermissionsModule} from "./shared/permissions/permissions.module";
import {AxAngularMapAdminModule} from "@ax/ax-angular-map-admin";
import {LeafletModule} from "./shared/leaflet/leaflet.module";


const handleNavigationPromise = (promise: Promise<boolean>) => {
  promise.then(success => {
    if (!success) {
      console.log('Navigation failed!');
    }
  }).catch(error => {
    console.error('Navigation error!', error);
  });
};

const servicesModule = ServicesModule.forRoot();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',

})
export class AppComponent {
  title: string;
  displaySideBar = false;
  availableRoles: string[];
  currentRole: string;
  assertingRole: boolean;
  isAdmin: boolean;
  isAuthenticated = false;
  faTv = faTv;
  faDesktop = faDesktop;
  faMap = faMap;
  user: User;
  faCodeBranch = faCodeBranch;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MyActiveOperations = PREDEFINED_NAVIGATIONS.MyActiveOperations;
  ussName: string;
  classificationLevel: string;
  myOperationClasses: string[] = ['click-link'];
  operationClasses: string[] = ['click-link'];
  showLogoutPrompt = false;
  timeLeftMsg: string;
  logoutBuffer = 5 * 60 * 1000;
  uiScale = 1.0;
  enableExperimentalDashboardSupport = DEFAULT_EXPERIMENTAL_SETTINGS.enableDashboardSupport;
  enableExperimentalDirectMessagingSupport = DEFAULT_EXPERIMENTAL_SETTINGS.enableDirectMessagingSupport;
  permissionService = inject(PermissionService);
  audioAlertsService = inject(AudioAlertsService);

  proposedOperationsCount$ = toSignal(combineLatest([
    this.permissionService.evaluateRequest(PermissionParser.parseAxPermissionRequest('submit_approvals')),
    timer(100, 10000)
  ]).pipe(exhaustMap(([canSubmit, i]) => {
    if (!canSubmit){
      return of(0);
    }
    // Check if the browser is able to autoplay audio
    this.audioAlertsService.refreshAutoplayPermitted();
    return this.approvalService.getOperationApprovals([ApprovalStatus.PENDING], 10, 0, true)
      .pipe(catchError(() => (EMPTY)))
      .pipe(map((searchResult) => {
        // Play alert sound when new approval requests are received
        if (!isNil(this.prevProposedOperationsCount) && searchResult.total > this.prevProposedOperationsCount) {
          this.audioAlertsService.playAudioAlert(AudioAlertType.PENDING_APPROVAL);
        }
        this.prevProposedOperationsCount = searchResult.total;
        return searchResult.total;
      }));
  })));

  responsiveService$ = inject(ResponsiveScreenService);
  ussSettings$ = toSignal(inject(SettingsService).getRawSettings());

  private activeComponent: any;
  // private authSubject = new ReplaySubject<boolean>(1);
  private hasUnreadMessages: boolean;
  private isOperationsRoute: boolean;
  private authRedirectUrl: string = null;
  private externalAuthMessageListener: (event) => void;
  private externalAuthChannel: BroadcastChannel;
  private prevProposedOperationsCount: number;

  private authSub: Subscription;
  private messageCheckTimerSubscription: Subscription;
  private messageWatchSubscription: Subscription;
  private unreadMessageCountWatchSubscription: Subscription;
  private logoutSub: Subscription;
  private timeLeftSub: Subscription;
  private routerEventSub: Subscription;
  private operationSub: Subscription;
  private megaSub: Subscription;
  private refreshSub: Subscription;
  private settingsSubs: Subscription[] = [];

  constructor(private auth: AuthService,
              public envService: SettingsService,
              private router: Router,
              private route: ActivatedRoute,
              private userMessageService: UserMessageService,
              private userSettingService: UserSettingsService,
              @Inject(DOCUMENT) private document: HTMLElement,
              private titleService: Title,
              private directMessageTargetIdService: DirectMessageTargetIdService,
              private approvalService: ApprovalService) {

    this.externalAuthChannel = new BroadcastChannel('uss-auth');

    this.externalAuthMessageListener = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const {message, source} = event.data;

      if(source === this.envService.getCurrentId()) {
        return;
      }

      if (message === 'logout') {
        this.logout(false);
      }

      if (message === 'login') {
        this.auth.checkLoginStatus();
        handleNavigationPromise(this.router.navigate(['/']));
      }
    };
    this.externalAuthChannel.addEventListener('message', this.externalAuthMessageListener);


    this.megaSub = combineLatest([this.auth.watchCurrentUser(), this.router.events]).subscribe(([user, routeEvent]) => {

      this.isAuthenticated = !!user;
      if (!(routeEvent instanceof NavigationStart)) {
        return;
      }
      const isAuthPath = routeEvent.url.startsWith('/login') || routeEvent.url.startsWith('/logout');
      if (!this.isAuthenticated) {
        if (!isAuthPath) {
          this.authRedirectUrl = routeEvent.url;
          handleNavigationPromise(this.router.navigate(['login']));
        }
        return;
      }
      if (this.authRedirectUrl) {
        const authRedirectUrl = this.authRedirectUrl;
        this.authRedirectUrl = null;
        handleNavigationPromise(this.router.navigateByUrl(authRedirectUrl));
        return;
      }

      if (!isAuthPath) {
        this.refreshToken();
      }

    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setScreenWidth();
  }

  logout(emitMessage: boolean = true) {
    this.user = null;
    this.availableRoles = null;
    this.currentRole = null;
    this.showLogoutPrompt = false;
    this.refreshSub?.unsubscribe();
    if (emitMessage) {
      this.externalAuthChannel.postMessage({
        message: 'logout',
        source: this.envService.getCurrentId()
      });
    }
    const nodeList = document.querySelectorAll('.main-container.open-overflow-menu');
    nodeList.forEach(node => node.className = 'main-container');

    this.auth.logout().subscribe(() => {
      handleNavigationPromise(this.router.navigate(['login']));
    });

  }

  ngOnInit(): void {
    this.setScreenWidth();

    this.initAuth();
    this.operationSub = combineLatest([this.router.events, this.route.queryParams]).subscribe(([route, params]) => {
      if (route instanceof NavigationEnd) {
        this.isOperationsRoute = route.url.startsWith('/fuss/operations/ops');

        this.myOperationClasses = ['click-link'];
        this.operationClasses = ['click-link'];
        if (this.isOperationsRoute) {
          if (
            (params.scopes && (params.scopes === 'personal' || _.isEqual(['personal'], params.scopes))) &&
            (params.state && _.isEqual(['ACCEPTED', 'ACTIVATED'], params.state))) {
            this.myOperationClasses = ['click-link', 'active'];
          } else {
            this.operationClasses = ['click-link', 'active'];
          }
        }
      }
    });

    this.settingsSubs.push(this.envService.getTitle().subscribe(title => {
      this.titleService.setTitle(title);
      this.ussName = title;
      this.title = title;
    }));

    this.settingsSubs.push(this.envService.getExperimentalSettings().subscribe(settings => {
      this.enableExperimentalDashboardSupport = settings.enableDashboardSupport || DEFAULT_EXPERIMENTAL_SETTINGS.enableDashboardSupport;
      this.enableExperimentalDirectMessagingSupport = settings.enableDirectMessagingSupport || DEFAULT_EXPERIMENTAL_SETTINGS.enableDirectMessagingSupport;
    }));

    this.settingsSubs.push(this.envService.getClassificationLevel().subscribe(classificationLevel => {
      this.classificationLevel = classificationLevel;
    }));

    this.settingsSubs.push(this.userSettingService.getUIScalingFactor().subscribe(scaleFactor => {
      this.uiScale = scaleFactor;
      (this.document.children[0] as HTMLElement).style.fontSize = `${20 * scaleFactor}px`;
    }));

  }

  assertRole(role: string) {
    this.assertingRole = true;
    this.auth.assertRole(this.user, role).subscribe(() => {
      this.assertingRole = false;
      handleNavigationPromise(this.router.navigate(['/']));
    });
  }

  getNotificationClasses(): false | 'danger' {

    if (this.hasUnreadMessages) {
      return 'danger';
    }
    return false;
  }

  getPrettyRoleName(role: string): string {
    switch (role) {
      case 'op':
        return 'Operator';
      case 'pilot':
        return 'Pilot';
      case 'pubsafety':
        return 'Public Safety';
      case 'safetycoord':
        return 'Safety Coordinater';
      case 'fleetman':
        return 'Fleet Manager';
      case 'conman':
        return 'Constraint Manager';
      case 'isr':
        return 'Intelligence, Surveillance, and Reconnaissance';
      case 'com':
        return 'Commander';
      case 'mcom':
        return 'Mission Commander';
      case 'atc':
        return 'Air Traffic Controller';
      case 'admin':
        return 'Administrator';
      case 'dev':
        return 'Developer';
      default:
        return role;
    }
  }

  initLogoutPrompt() {
    this.showLogoutPrompt = true;

    this.timeLeftSub = timer(1, 1000).subscribe(() => {
      const timeLeftMs = Math.floor((this.user.getExpiration() - Date.now()) / 1000) * 1000;

      // const timeLeftDuration = Duration.fromMillis(timeLeftMs);
      this.timeLeftMsg = humanizeDuration(timeLeftMs);
    });
  }

  refreshToken() {
    this.showLogoutPrompt = false;
    this.refreshSub?.unsubscribe();
    this.refreshSub = this.auth.refreshLogin().subscribe(() => {
      // Nothing to do
    });
  }

  openSendMessageModal() {
    this.directMessageTargetIdService.notifyOfTargetChange({targetType: TargetEnum.USER, id: 'user'});
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.messageWatchSubscription?.unsubscribe();
    this.unreadMessageCountWatchSubscription?.unsubscribe();
    this.messageCheckTimerSubscription?.unsubscribe();
    this.logoutSub?.unsubscribe();
    this.timeLeftSub?.unsubscribe();
    this.routerEventSub?.unsubscribe();
    this.operationSub?.unsubscribe();
    this.megaSub?.unsubscribe();
    this.refreshSub?.unsubscribe();
    this.settingsSubs.forEach(s => s?.unsubscribe());
    this.externalAuthChannel?.removeEventListener('message', this.externalAuthMessageListener);
  }

  onRouterOutletActivate($event: any) {
    this.activeComponent = $event;
  }

  private initAuth() {

    // window.addEventListener('message', this.logoutMessageListener);

    this.auth.watchCurrentUser().subscribe((user: User | null) => {
      // TODO: Reduce calls to settings somehow, e.g. if user id has changed or (user id hasn't changed and not enough time has passed)
      this.envService.refreshSettings();

      if (user == null) {
        this.isAdmin = false;
        // this.authSubject.next(false);

        this.userSettingService.refreshSettings(true);
        this.timeLeftSub?.unsubscribe();
        this.logoutSub?.unsubscribe();
        this.showLogoutPrompt = false;
        this.postAuthCheckFailInit();
      } else {
        this.user = user;
        this.isAdmin = user.isAdmin();
        // this.authSubject.next(true);
        const roleSub = new Subscription();
        roleSub.add(this.auth.getRoles(user).subscribe((roleResponse: RoleResponse) => {
          this.availableRoles = roleResponse.roles;
          this.currentRole = user.getCurrentRole();
          roleSub.unsubscribe();
        }));
        this.userSettingService.refreshSettings();

        this.initLogoutWarning();

        this.logoutSub.add(
          timer(1, 1000).subscribe(() => {
            if (this.user.expiration - Date.now() <= 0) {
              this.logout();
            }
          })
        );

        this.postAuthCheckInit();
      }

    });
  }

  private postAuthCheckInit() {
    if (!this.messageWatchSubscription) {

      this.messageWatchSubscription = this.userMessageService.watchMessages().subscribe((messages: UserMessageEntry[]) => {
        this.hasUnreadMessages = messages.length > 0;
      });
      this.unreadMessageCountWatchSubscription = this.userMessageService.watchUnreadMessageCount().subscribe((count: number) => {
        this.hasUnreadMessages = count > 0;
      });
      this.messageCheckTimerSubscription = timer(100, 30 * 1000).subscribe(() => {
        this.userMessageService.refreshUnreadMessageCount();
      });
    }
    //
    // if(this.authRedirectUrl){
    //   this.router.navigateByUrl(this.authRedirectUrl);
    //   this.authRedirectUrl = null;
    // }

  }

  private postAuthCheckFailInit() {
    this.messageWatchSubscription?.unsubscribe();
    this.unreadMessageCountWatchSubscription?.unsubscribe();
    this.messageCheckTimerSubscription?.unsubscribe();

    this.messageWatchSubscription = undefined;
    this.unreadMessageCountWatchSubscription = undefined;
    this.messageCheckTimerSubscription = undefined;

    // this.router.navigate(['login']);
  }

  private setScreenWidth() {
    this.responsiveService$.screenWidth$.set(window.innerWidth);
  }

  private initLogoutWarning() {
    if (!this.user) {
      return;
    }
    this.timeLeftSub?.unsubscribe();
    this.logoutSub?.unsubscribe();
    this.logoutSub = new Subscription();
    const timeTillLogout = this.user.getExpiration() - Date.now();
    const logoutTs = timeTillLogout - this.logoutBuffer;
    if (timeTillLogout < 0) {
      this.logout();
      return;
    }
    if (logoutTs < 0) {
      this.initLogoutPrompt();
      return;
    }
    this.logoutSub.add(
      timer(1, 1000).subscribe(() => {
        if (this.showLogoutPrompt) {
          return;
        }
        if ((this.user.getExpiration() - Date.now() - this.logoutBuffer) <= 0) {
          this.initLogoutPrompt();
        }
      })
    );
  }
}

import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Alert} from '../shared/model/Alert';
import {User} from '../shared/model/User';
import {AuthService} from '../shared/services/auth.service';
import {Router} from '@angular/router';
import {SettingsService} from '../shared/services/settings.service';
import {ILoginResponse} from '../shared/model/LoginResponse';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {FormControlify} from '../shared/utils/forms';
import {forbiddenPatternRegexes, invalidCharactersValidator} from '../shared/utils/Validators';
import {toSignal} from '@angular/core/rxjs-interop';
import {ClrAlertModule, ClrInputModule, ClrPasswordModule} from "@clr/angular";
import {NgForOf} from "@angular/common";

interface ILoginFG {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ClrInputModule,
    ClrPasswordModule,
    ClrAlertModule,
    NgForOf
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  alerts: Alert[];
  ussName = 'Clue Federal USS';
  formGroup: FormGroup<FormControlify<ILoginFG>>;

  ussSettings$ = toSignal(inject(SettingsService).getRawSettings());

  private externalAuthChannel: BroadcastChannel;
  private ussNameSub: Subscription;
  private logoutSub: Subscription;

  constructor(private loginService: AuthService, public envService: SettingsService, private router: Router) {
    this.ussNameSub = this.envService.getUssName().subscribe((name) => {
      this.ussName = name;
    });
    this.externalAuthChannel = new BroadcastChannel('uss-auth');
  }

  ngOnDestroy(): void {
        this.ussNameSub?.unsubscribe();
        this.logoutSub?.unsubscribe();
    }

  ngOnInit() {
    this.logoutSub = this.loginService.logout().subscribe(res => {
    });

    this.formGroup = new FormGroup<FormControlify<ILoginFG>>({
      email: new FormControl<string>(null, [Validators.required, Validators.email]),
      password: new FormControl<string>(null, [Validators.required, invalidCharactersValidator(forbiddenPatternRegexes.password)])
    });
  }

  handleLogin() {
    this.alerts = [];

    this.loginService.login(this.formGroup.controls.email.value, this.formGroup.controls.password.value).subscribe((res: User | ILoginResponse) => {
      const fail = res as ILoginResponse;
      if (fail.success === false) {
        this.alerts.push(new Alert(`Login failed: ${fail.message}`, 'danger'));
        return;
      }
      try {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberme');
      } catch (e) {
        console.error(e);
      }

      this.externalAuthChannel.postMessage({message: 'login', source: this.envService.getCurrentId()});
      this.router.navigate(['/']).then().catch(error => console.error(error));
    }, (err: string) => {
      this.alerts.push(new Alert(err, 'danger'));
    });

  }
}

<div class="login-wrapper">
  <form [formGroup]="formGroup" class="login">
    <h1 style="width:100%;margin:0 auto;text-align:center; padding-bottom:50px;">{{ussName}}</h1>
    <div class="login-group">
      <clr-alert *ngFor="let alert of alerts" [clrAlertType]="alert.Level" [clrAlertClosable]="true">
        <clr-alert-item>
            <span class="alert-text">
              {{alert.Message}}
            </span>
        </clr-alert-item>
      </clr-alert>

      <clr-input-container>
        <input autocomplete="email" type="text" formControlName="email" clrInput placeholder="Email"/>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'email'">Invalid email format</clr-control-error>
      </clr-input-container>
      <clr-password-container>
        <input autocomplete="password" type="password" formControlName="password" clrPassword placeholder="Password"/>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.password.errors.invalidCharacters.characters}}</clr-control-error>
      </clr-password-container>
      <button type="submit" class="btn btn-primary" (click)="handleLogin()" [disabled]="formGroup.invalid && formGroup.touched">
        Login</button>
    </div>
    @if (ussSettings$()?.laanc) {
      <div class="laanc-footer" cds-text="secondary">
        <p class="laanc-statement">The AX Enterprize LAANC USS is a provider of UAS services within the FAA’s Low-Altitude
          Authorization and Notification Capability (LAANC). LAANC may be used to satisfy compliance with Air Traffic
          authorization. Information provided here is based on real-time and available projected information on airspace
          status and airport-specific maps, and that information is subject to change. Planning tools should be checked
          prior to flight for any changes that could impact the operation.</p>
        <p><a href="https://www.faa.gov/uas/programs_partnerships/data_exchange/privacy_statement" target="_blank">FAA Privacy Statement</a></p>
      </div>
    }
  </form>
</div>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {appRoutes} from './app.routes';
import {FussModule} from "./fuss/fuss.module";


@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {}), FussModule/*, TestModule*/],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

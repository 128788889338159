import { Route } from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {LoginComponent} from './login/login.component';
import {NonAuthGuard} from './auth/non-auth.guard';
import {HomeComponent} from './home/home.component';
import {MessagesComponent} from './messages/messages.component';
import {SettingsComponent} from './settings/settings.component';
import {PermissionGuardService} from './shared/permissions/PermissionGuardService';
import {UserSettingsComponent} from './shared/components/user-settings/user-settings.component';
import {ErrorScreenComponent} from './shared/components/error-screen/error-screen.component';

export const appRoutes: Route[] = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'messages', component: MessagesComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent, canActivate: [NonAuthGuard]},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('submit_setting')]},
  {path: 'fuss', loadChildren: () => import('./fuss/fuss.module').then(m => m.FussModule), canActivate: [AuthGuard]},
  {path: 'user-settings', component: UserSettingsComponent, canActivate: [AuthGuard]},
  {path: 'permission-error', component: ErrorScreenComponent, canActivate: [AuthGuard]},
  {path: 'telemetry', loadComponent: () => import('./telemetry-status/telemetry-status.component').then(m => m.TelemetryStatusComponent), canActivate: [AuthGuard]},
  {path: 'laanc', loadChildren: () => import('./laanc/laanc-routing.module').then(m => m.LaancRoutingModule), canActivate: [AuthGuard]}
];

import {Component} from '@angular/core';
import {ComponentsModule} from "../shared/components/components.module";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  standalone: true,
  imports:[
    ComponentsModule
  ]

})
export class MessagesComponent {

  constructor() {
    // Method not implemented
  }

}

import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../shared/services/settings.service';
import {User} from '../shared/model/User';
import {AuthService} from '../shared/services/auth.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Polygon} from "../shared/model/gen/utm";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf
  ]
})
export class HomeComponent implements OnInit {
  public user: User;
  basic = true;

  fg = new FormGroup({
    polygon: new FormControl<Polygon>(null, [Validators.required]),
  });
  constructor(public envService: SettingsService, public UserService: AuthService, ) {
  }

  ngOnInit() {

    this.UserService.watchCurrentUser().subscribe(user => {
      this.user = user;
    });

  }

  add() {
    console.log("Adding:", this.fg.value.polygon);
  }
}
